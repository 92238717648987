<template>
	<BaseLoading v-if="isLoading" is-full-page />
	<form
		v-else
		class="main-wrapper mt-4"
		@submit.prevent="handleSubmit"
	>
		<CRow class="mb-5">
			<CCol
				md="12"
				class="d-flex justify-content-between align-items-center mb-4"
			>
				<h4 class="marketing-category-name">
					{{ attributeInfo.name }}
				</h4>
				<div class="marketing-category-id">
					<span class="typo-caption-2 mr-1">Attribute set ID:</span>
					<span class="typo-caption-2 font-weight-bold">
						{{ $route.params.id }}
					</span>
				</div>
			</CCol>
			<CCol md="12">
				<div class="d-block">
					<label class="mb-0">
						<h3 class="typo-label">Attributes</h3>
						<p class="typo-caption color-black-45">Add product attribute and drag to reposition. It will be<br> displayed in product detail specification section.</p>
					</label>
					<CButton
						type="button"
						class="btn btn-add float-right"
						color="secondary"
						data-test-id="button-add"
						@click="$refs['modal-add-keys'].open()"
					>
						<CIcon class="icon-left" name="cil-plus" />
						Add attributes
					</CButton>
				</div>
			</CCol>
			<CCol lg="12 mt-4">
				<div v-if="!keys.length" class="empty-key">
					There are no attributes in this set
				</div>
				<AttributeSetEditKeys
					v-else
					v-model="keys"
					@onChange="handleKeyChange"
				/>
			</CCol>
		</CRow>

		<BaseActionPanelStickyFooter
			content-class="main-wrapper"
			is-edit
			remove-text="Remove attribute set"
			data-test-id="attribute-set-footer"
			@onConfirm="handleSubmit"
			@onCancel="$router.push({ name: 'AttributeSetList'})"
			@onRemove="$refs['modal-remove'].open()"
		/>

		<BaseModalConfirm
			ref="modal-confirm"
			:is-submitting="edit.isUpdating"
			title="Save confirmation?"
			description="All changes will take effect immediately."
			primary-button-text="Confirm save"
			primary-button-loading-text="Saving"
			@onConfirm="handleConfirm"
		/>
		<AttributeSetEditModalKeys
			ref="modal-add-keys"
			:selected-keys="keys"
			:attribute-keys="attributeKeys.data"
			@onAdd="handleAddKeys"
		/>
		<ModalRemoveAttribute
			ref="modal-remove"
			:handle-remove="deleteAttributeSet.bind(null, $route.params.id)"
			title="Remove this attribute set?"
			description="This will remove the set, its attributes and all its values from all products currently using this set."
			data-test-id="attribute-set-remove-modal"
			@onSuccess="$router.replace({ name: 'AttributeSetList' })"
		/>
	</form>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex';
import BaseModalConfirm from '@/components/BaseModalConfirm.vue';
import AttributeSetEditKeys from '@/components/AttributeSetEditKeys.vue';
import AttributeSetEditModalKeys from '@/components/AttributeSetEditModalKeys.vue';
import ModalRemoveAttribute from '@/components/ModalRemoveAttribute.vue';

export default {
	name: 'AttributeSetEdit',

	components: {
		BaseModalConfirm,
		AttributeSetEditKeys,
		AttributeSetEditModalKeys,
		ModalRemoveAttribute,
	},
	data() {
		return {
			id: this.$route.params.id,
			isLoading: false,
			keys: [],
		};
	},
	computed: {
		...mapState('attributeSets', {
			edit: 'edit',
		}),
		...mapState('attributeKeys', {
			attributeKeys: 'list',
		}),
		...mapGetters({
			attributeInfo: 'attributeSets/attributeInfo',
		}),
	},
	async created() {
		this.isLoading = true;
		await this.getAttributeSet(this.id);
		await this.getAttributeKeys({ per_page: 'all' });
		this.isLoading = false;

		this.keys = this.attributeInfo.attributeKeys;
		this.setPageTitle({
			title: this.attributeInfo.name,
			isShow: true,
		});
	},
	methods: {
		...mapActions({
			setPageTitle: 'pageTitle/setPageTitle',
			getAttributeSet: 'attributeSets/getAttributeSet',
			getAttributeKeys: 'attributeKeys/getAttributeKeys',
			updateAttributeSet: 'attributeSets/updateAttributeSet',
			deleteAttributeSet: 'attributeSets/deleteAttributeSet',
			showToast: 'toast/showToast',
		}),
		handleKeyChange(keys) {
			this.keys = keys;
		},
		handleAddKeys(keyIds = []) {
			const newKeys = this.attributeKeys.data.filter((key) => keyIds.some((id) => id === key.id));
			this.keys = [...newKeys, ...this.keys];
		},
		handleSubmit() {
			this.$refs['modal-confirm'].open();
		},
		async handleConfirm() {
			const attributeKeys = this.keys.map((key) => key.id);
			await this.updateAttributeSet({
				id: this.id,
				params: {
					attribute_key_ids: attributeKeys,
				},
			});

			this.$refs['modal-confirm'].close();
		},
	},
};
</script>

<style lang="scss" scoped>
	.set-id {
		color: $color-black-45;
		font-weight: $font-weight-bold;
	}

	.marketing-category-name {
		flex: 1;
		max-width: 70%;
	}

	.empty-key {
		@include typo-subtitle;

		display: flex;
		align-items: center;
		justify-content: center;
		height: rem(138);
		color: $color-black-45;
		background-color: $color-gray-100;
	}
</style>
