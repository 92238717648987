<template>
	<CModal
		ref="modal"
		:show.sync="isShow"
		centered
		class="modal-remove"
		data-test-id="modal"
		@update:show="close"
	>
		<template #header-wrapper>
			<ModalHeaderWrapper
				ref="modal-header-wrapper"
				:title="title"
				data-test-id="modal-header-wrapper"
				@onClose="close"
			/>
		</template>
		<template #default>
			<div data-test-id="modal-description">
				{{ description }}
			</div>
			<CForm @submit.prevent="handleSubmit">
				<CInput
					ref="input-text"
					v-model="inputText"
					type="text"
					class="mt-3"
					placeholder="type REMOVE to confirm"
					data-test-id="input-text"
					data-id="remove-text"
				/>
			</CForm>
		</template>
		<template #footer>
			<CButton
				:disabled="isSubmitting"
				class="modal-button transparent mr-3"
				color="tertiary"
				data-test-id="cancel-button"
				@click="close"
			>
				Cancel
			</CButton>
			<CButton
				:disabled="isSubmitting || inputText !== REMOVE"
				class="modal-button transparent"
				color="danger"
				data-test-id="primary-button"
				@click="handleSubmit"
			>
				{{ isSubmitting ? "Removing" : "Remove" }}
			</CButton>
		</template>
	</CModal>
</template>

<script>
import ModalHeaderWrapper from './ModalHeaderWrapper.vue';
import { REMOVE } from '../enums/general';

export default {
	name: 'ModalRemoveAttribute',
	components: {
		ModalHeaderWrapper,
	},
	props: {
		title: {
			type: String,
			default: null,
		},
		description: {
			type: String,
			default: null,
		},
		handleRemove: {
			type: Function,
			default: () => {},
		},
	},
	data() {
		return {
			REMOVE,
			isShow: false,
			isSubmitting: false,
			inputText: '',
		};
	},
	methods: {
		async open() {
			this.isShow = true;
			await this.$nextTick();
			this.$refs['input-text'].$el.querySelector('input').focus();
		},
		async handleSubmit() {
			if (this.inputText !== REMOVE) return;
			this.isSubmitting = true;
			try {
				await this.handleRemove();
				this.$emit('onSuccess');
				this.close();
			} catch (e) {
				this.$emit('onFailure');
			}
			this.isSubmitting = false;
		},
		close() {
			this.isShow = false;
			this.inputText = '';
		},
	},
};
</script>

