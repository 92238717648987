<template>
	<div>
		<draggable
			:value="value"
			tag="ul"
			ghost-class="is-placeholder-item"
			class="attribute-list"
			@input="handleKeyOrder"
		>
			<li
				v-for="attributeKey in value"
				:key="attributeKey.id"
				class="attribute-list-item"
			>
				<span class="attribute-list-item-name">{{ attributeKey.name }}</span>
				<CButton
					class="btn-delete p-0"
					@click.prevent="handleRemoveKey(attributeKey.id)"
				>
					<CIcon
						class="icon-trash"
						name="cil-trash"
					/>
				</CButton>
			</li>
		</draggable>
	</div>
</template>

<script>
import draggable from 'vuedraggable';

export default {
	name: 'AttributeSetEditkeys',

	components: {
		draggable,
	},
	props: {
		value: {
			type: Array,
			default: () => [],
		},
	},
	data() {
		return {
			keys: this.value,
		};
	},
	methods: {
		handleRemoveKey(id) {
			this.keys = this.keys.filter((key) => key.id !== id);

			this.$emit('input', this.keys);
		},
		handleKeyOrder(sortedKey) {
			this.$emit('input', sortedKey);
		},
	},
};
</script>

<style lang="scss" scoped>
	.icon-trash {
		color: $color-black-25;
	}

	.attribute-list {
		min-height: rem(36);
		border-radius: 4px;
		background-color: $color-gray-100;
		list-style-type: none;
		padding: 2px;
		margin: 0;

		// .attribute-list-item
		&-item {
			position: relative;
			z-index: 1;
			display: flex;
			align-items: center;
			padding: rem(6) rem(12);
			background-color: $color-white;
			cursor: move;
			border-radius: 4px;
			transition: $transition-duration background-color;
			margin-bottom: 2px;

			&:last-child {
				margin-bottom: 0;
			}

			// .attribute-list-item-name
			&-name {
				@include typo-body-2;
				@include ellipsisOneline;

				flex: 1;
				margin: 0 rem(16) 0 0;
			}

			&.is-placeholder-item {
				opacity: 0.5;
			}
		}
	}
</style>
